body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* To get the react app to always consume full height.
 * From: https://stackoverflow.com/a/40240359
 */
[data-reactroot]
        {
          height: 100% !important;
          min-height: 100% !important;
        }

html, body, body>div {
  min-height: 100%;
  height: 100%;
}
