////////////////////////////////////////////////////////////////////////////////
// Global Constants
////////////////////////////////////////////////////////////////////////////////
// Sizes
$max-mobile-width: 800px;

// Colors
//$vim-white: #D0D0D0;
$vim-white: whitesmoke;
$vim-yellow-white: #D7D7AF;
$vim-gray: #3A3A3A;
$vim-black: #303030;
$vim-light-black: #353535;
$vim-dark-black: #262626;
$vim-light-green: #98BCBD;
$vim-dark-green: #5F875F;
$vim-pink: #C3A9AF;
$vim-red: #D75587;
$vim-blue: #5FAFAF;
$vim-light-blue: #87AFD7;
$vim-green-blue: #87AFAF;
$vim-orange: #FFAF87;
$vim-brown: #D7AF5F;
$vim-yellow: #FFD787;

$translucent-whitesmoke: rgba(245,245,245,0.75);


////////////////////////////////////////////////////////////////////////////////
// Common mixins
////////////////////////////////////////////////////////////////////////////////
@mixin noMarginNoPadding {
  margin: 0px;
  padding: 0px;
}
@mixin noPadding {
  padding: 0px;
}
@mixin minorMargin {
  margin-top: 25px;
  margin-bottom: 25px;
}
@mixin borderRadius {
  border-radius: 15px;
}


////////////////////////////////////////////////////////////////////////////////
// Global element styles/defaults
////////////////////////////////////////////////////////////////////////////////
button {
  cursor: pointer;
}
a {
  color: $vim-blue;
}
a:visited {
  color: $vim-dark-green;
}

h1 {
  margin: 15px;
}
h2 {
  color: white;
}
h3 {
  color: white;
}
figcaption {
  font-size: small;
}
section {
  padding-left: 5px;
  padding-right: 5px;
}
@media only screen and (min-width: $max-mobile-width) {
  section {
    max-width: 75%;
    padding-left: 150px;
    padding-right: 150px;
  }
}

////////////////////////////////////////////////////////////////////////////////
// Top-level styles
////////////////////////////////////////////////////////////////////////////////

// Router Top/Layout
.Layout {
  background-color: $vim-gray;
  text-align: center;
  height: 100%;
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: space-between;  // App to top, footer to bottom
}

// Div containing Menu panel and main app (not footer)
.MenuAndApp {
  display: flex;
  flex-direction: row;
}

// Menu
.MenuParent {
  z-index: 2;
  background-color: $vim-black;
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0px; /*100px to give some room for the sidebar heading (without this, absolute position will make the nav overlap)*/
  left: 0px; /* you can make this zero and add `padding-left: 15px` */
  min-width: max-content;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: calc(100vh - 40px); // screen height minus padding
}
.MenuRoutes {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
}
.MenuRoutes button {
  color: whitesmoke;
  font-size: x-large;
  font-weight: bold;
  background-color: $vim-black;
  border: none;
  text-align: left;
  padding: 10px;
}
.LonelyMenuButtonContainer {
  position: fixed;
  top: 0px;
  left: 0px;
}
.LonelyMenuButton {
  background-color: $translucent-whitesmoke;
  color: #303030;
  font-size: xxx-large;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  margin-right: 5px;
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
}
/* Menu Icon is generated by 3 horizontal lines */
.MenuIconLine {
  width: 35px;
  height: 5px;
  background-color: whitesmoke;
  margin: 6px 0;
}
.MenuToggleButton {
  color: whitesmoke;
  background-color: $vim-black;
  border: none;
  text-align: left;

}
@media only screen and (max-width: $max-mobile-width) {
  .MenuParent {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .MenuRoutes {
      padding-right: 10px;
  }
  .MenuRoutes button {
    font-size: xx-large;
  }
  .MenuIconLine {
    background-color: $vim-gray;
  }
}

// App (router page)
.App {
  width: 100%;
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 30vmin;
  max-height: 300px;
  pointer-events: none;
  padding-bottom: 10px;
}

.App-header {
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: $vim-gray;
  padding: 15px;
}
.App-header > a {
  color: inherit;
  text-decoration: none;
}

.App-header > button {
  margin: 5px;
}

.App-body {
  background-color: $vim-gray;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  text-align: left;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
  padding: 25px;
}
.App-body button {
  color: $vim-blue;
  font-size: inherit;
  background-color: $vim-gray;
  border: none;
}

// Site Footer - present in all router pages - part of Layout
.Footer {
  background-color: whitesmoke;
  color: $vim-gray;
  padding: 25px;
}
.Footer > * {
    padding: 10px;
}
.Footer > a {
    color: $vim-gray;
}
//.Footer > a:visited {
//    color: $vim-gray;
//}


////////////////////////////////////////////////////////////////////////////////
// Sub-component and per-element styles
////////////////////////////////////////////////////////////////////////////////
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.ProjectCard {
  background-color: $vim-black;
  width: 350px;
  height: 650px;
  padding: 25px;
  margin: 25px;
  color: whitesmoke;
  text-decoration: none;
  justify-content: space-between;
  text-align: left;
  @include borderRadius;
  transition: transform .1s ease-in-out;
  box-shadow: 5px 5px $vim-dark-black;
}
.ProjectCard:hover {
  background-color: $vim-light-black;
  transform: scale(1.02);
  box-shadow: 5px 5px black;
}
.ProjectCard h3 {
  text-align: center;
  margin: 20px;
}
.ProjectCard p {
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 30px;
}
.ProjectCard:visited {
  color: whitesmoke;
}
.Resume > h3 {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.Resume ul {
  padding-top: 0px;
  margin-top: 10px;
}
.horizontal-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.vertical-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vertical-left {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
}
.resumeLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.menuHeaderParent {
  width: fit-content;
  text-align: center;
  //margin-left: 6.5px;
}
.menuLogo {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  padding: 0px;
  margin-bottom: 15px;
}
.menuIcon {
  width: 25px;
  height: 25px;
  border-radius: 3px;
  vertical-align: middle;
  margin-bottom: 3px;
}
.smallImagePreview {
  padding: 5px;
  width: auto;
  max-height: 159px;
  @include borderRadius;
}
.mediumImagePreview {
  padding: 5px;
  width: auto;
  max-height: 200px;
  @include borderRadius;
}
.projectImagePreview {
  padding: 5px;
  width: auto;
  max-height: 300px;
  @include borderRadius;
}
.wideImagePreview {
  margin: 15px;
  padding: 5px;
  width: 560px;
  height: auto;
  @include borderRadius;
}
.cardImagePreview {
  padding: 5px;
  width: 275px;
  @include borderRadius;
}
.imagePreview {
  padding: 5px;
  width: auto;
  max-height: 500px;
  @include borderRadius;
}
.galleryImage {
  margin: 20px;
  width: auto;
  max-width: 80%;
  max-height: 500px;
  @include borderRadius;
}
.galleryImageLong {
  margin: 20px;
  width: auto;
  max-width: 80%;
  max-height: 1000px;
  @include borderRadius;
}
.galleryImageXLong {
  margin: 20px;
  width: auto;
  max-width: 80%;
  height: 1430px;
  @include borderRadius;
}
@media only screen and (max-width: $max-mobile-width) {
  .menuIcon {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    vertical-align: middle;
    margin-bottom: 3px;
  }
  .wideImagePreview {
    max-height: 400px;
  }
  .imagePreview {
    max-width: 195px;
    height:auto;
  }
  .galleryImage {
    max-width: 300px;
    height:auto;
  }
  .galleryImageLong {
    max-width: 300px;
    height:auto;
  }
  .galleryImageXLong {
    max-width: 300px;
    height:auto;
  }
}
@media only screen and (max-width: 650px) {
  .wideImagePreview {
    width: 370px;
  }
  .cardImagePreview {
    width: 180px;
  }
}
@media only screen and (max-width: 400px) {
  .ProjectCard {
    width: 220px;
    height: auto;
    padding: 15px;
    margin: 15px;
  }
  .ProjectCard h3 {
    margin: 10px;
  }
  .ProjectCard p {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
  }
  .projectImagePreview {
    max-height: 200px;
    @include borderRadius;
  }
  .wideImagePreview {
    width: 250px;
  }
  .cardImagePreview {
    width: 120px;
  }
  .galleryImage {
    max-width: 250px;
    height:auto;
  }
  .galleryImageLong {
    max-width: 250px;
    height:auto;
  }
  .galleryImageXLong {
    max-width: 250px;
    height:auto;
  }
}

.pointer {
  cursor: pointer;
}
.focused {
  color: $vim-yellow;
}


////////////////////////////////////////////////////////////////////////////////
// Farmgod Styles
////////////////////////////////////////////////////////////////////////////////
@font-face {
  font-family: 'Staatliches', cursive;
  src: local('Staatliches'), url(fonts/Staatliches-Regular.ttf) format('truetype');
}
@mixin farmgod-font {
  font-family: 'Staatliches';
}
$rich-black-fogra-39: #0c0f0aff;
$winter-sky: #ff206eff;
$lemon-glacier: #fbff12ff;
@mixin mainGradient {
  background: linear-gradient(90deg, $winter-sky, $lemon-glacier);
}
.farmgod-title {
  font-size: calc(10px + 1vmin);
  letter-spacing: 5px;
  text-decoration: none;
  @include minorMargin;
  @include noPadding;
  @include farmgod-font;

  span {
    @include mainGradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(0 0 4px $winter-sky)
  }
}
.farmgod-h1 {
  @include minorMargin;
  @include noPadding;
}
.h2.farmgod-title {
  font-size: calc(10px + 3vmin);
  text-align: left;
  align-items: left;
}
////////////////////////////////////////////////////////////////////////////////
// Cult NFTs
////////////////////////////////////////////////////////////////////////////////
.cult-title {
  font-size: calc(10px + 4vmin);
  color: whitesmoke;
  font-family: sans-serif;
  text-decoration: none;
  @include minorMargin;
  @include noPadding;
}
.cult-title:visited {
  color: whitesmoke;
}
.h2.cult-title {
  font-size: calc(10px + 4vmin);
  text-align: left;
}
////////////////////////////////////////////////////////////////////////////////
// Fantomon Styles
////////////////////////////////////////////////////////////////////////////////
$fantomon-yellow: #E9AB5F;
@font-face {
  font-family: 'Press Start 2P';
  src: local('Press Start 2P'), url(fonts/PressStart2P-vaV7.ttf) format('truetype');
}
@mixin fantomon-font {
  font-family: 'Press Start 2P', cursive;
}
.fantomon-title {
  color: $fantomon-yellow;
  font-size: calc(10px + 3vmin);
  text-shadow: .12rem .12rem #cdfddf;
  background-color: transparent;
  z-index: 2;
  text-decoration: none;
  @include minorMargin;
  @include noPadding;
  @include fantomon-font;
}
.fantomon-title:visited {
  color: $fantomon-yellow;
}
.h2.fantomon-title {
  font-size: calc(10px + 3vmin);
  text-align: left;
}


////////////////////////////////////////////////////////////////////////////////
// Effects and Animations
////////////////////////////////////////////////////////////////////////////////
.typewriter-container {
  // required in parent to typewrite text to prevent cursor from extending width
  display: inline-block;
}
.typewriter {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
}
@media (prefers-reduced-motion: no-preference) {
  .menuLogo:hover {
    animation: menu-logo-spin infinite 2.5s linear;
  }
  .typewriter {
    border-right: .70em solid $vim-gray;
    letter-spacing: .15em;
    animation:
      typing 1.5s steps(25, end),
      blink-caret .75s step-end infinite;
  }
}

@keyframes menu-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: $vim-gray; }
}
@keyframes blink-caret-green {
  from, to { border-color: transparent }
  50% { border-color: $vim-light-green; }
}
